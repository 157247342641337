import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";

import JT from 'img/mul/jt.png'
import JT2 from 'img/mul/jt2.png'
import Header from "components/header";
import Token1 from 'img/mul/token/1.png'
import Token2 from 'img/mul/token/2.png'
import Token3 from 'img/mul/token/3.png'
import Token4 from 'img/mul/token/4.png'
import tg from 'img/mul/Telegram2.png'
import tt from 'img/mul/Twitter2.png'
import docs from 'img/mul/docs2.png'
import p1 from 'img/mul/p1.png'
import p2 from 'img/mul/p2.png'
import LTC from 'img/mul/lct.png'

const MultiBitBox  = styled(Box)<{mb?:boolean}>`
      .disc{
          display: flex;
          padding: ${({mb})=> mb? '12px': '82px 140px 82px 220px;'};
          flex-direction:  ${({mb})=> mb? 'column ': 'inherit'};
          align-items: center;
          
         
        .lt{
          width: ${({mb})=> mb? '100%': '50%'};
          .titld{
            font-size: 68px;
            font-family: Poppins, Poppins;
            font-weight: bold;
            color: #FFFFFF;
            -webkit-text-stroke: 2px #0DDB9C;
            text-stroke: 2px #0DDB9C;
            -webkit-background-clip: text;
text-shadow: #0DDB9C 1px 0 10px;
text-shadow: 1px 1px 2px #FFFFFF, 0 0 1em #0DDB9C, 0 0 0.2em #0DDB9C;

-webkit-background-clip: text;
-webkit-text-fill-color: #FFFFFF;
          }
          .size{
            font-size: 20px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #d1d1d17d;
            margin-top:30px;
           
          }
          .contractsize{
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #D1D1D1;
            margin-top: 16px;
          }
          .swapbtn{
            margin-top: 36px;
            height: 50px;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            border: 1px solid #0DDB9C;
            display: flex;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            width: 212px;
            align-items: center;
            gap: 10px;
            justify-content: center;
            cursor: pointer;
            img{
              margin-top: 4px;
            }
          }
        }
        .rg{
             width: ${({mb})=> mb? '100%': '50%'};
             img{
              width:  ${({mb})=> mb? '100%': '600px'};
             }
        }
      }

      .tokenlist{
        background: #030F05;

        display: flex;
        justify-content: ${({mb})=> mb? 'inherit ': ' space-around'};
        padding: 28px 0;
        
        flex-direction:  ${({mb})=> mb? 'column ': 'inherit'};
        .token{
          
          display: flex;
          align-items: center;
            justify-content: ${({mb})=> mb? 'center ': ' space-around'};
          gap: 22px;
          font-size: 33px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
        }
      }

      .tsav{
       margin:  ${({mb})=> mb? '12px': '114px 202px 0 166px;'};
      }


      .list{
          display: flex;
          flex-direction: column;
          gap: 20px;
        
        .item{
          background: #030E05;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        border: 1px solid #0DDB9C;
        padding: 42px;
        &:hover{
           box-shadow: 1px 0px 30px  #0DDB9C;
        }
          .itmetitle{
            font-size: 56px;
            font-family: Poppins, Poppins;
            font-weight: bold;
            color: #0DDB9C;
            display: flex;
            align-items: center;
          
            .size2{
            font-size: 34px;
          font-family: Poppins, Poppins;
          font-weight: bold;
          color: #FFFFFF;
           margin-left: 32px;
         
          }
          }
          .itmesize{
            margin-top: 20px;
            font-size: 20px;
font-family: PingFang SC, PingFang SC;
font-weight: 400;
color: #D1D1D1;
          }

        }
        
      }
      
      .tiles{
        font-size: 68px;
font-family: Poppins, Poppins;
font-weight: bold;
color: #FFFFFF;
-webkit-text-stroke: 2px #0DDB9C;
text-stroke: 2px #0DDB9C;
text-shadow: #0DDB9C 1px 0 10px;
text-shadow: 1px 1px 2px #FFFFFF, 0 0 1em #0DDB9C, 0 0 0.2em #0DDB9C;

-webkit-background-clip: text;
-webkit-text-fill-color: #FFFFFF;
background-clip:text;
        text-align: center;
        margin-top: 160px;
      }
      .sizee{
        font-size: 20px;
font-family: PingFang SC, PingFang SC;
font-weight: 400;
color: #d1d1d17d;

margin-top: 62px;
text-align: center;
      }

      .bosd{
        height: 713px;
background: #030E05;
border-radius: 8px 8px 8px 8px;
opacity: 1;
border: 1px solid #0DDB9C;
margin-top: 40px;
      }

      .OrdinalsFarming{
          display: flex;
          justify-content: space-between;

           flex-direction:  ${({mb})=> mb? 'column ': 'inherit'};
          gap: 24px;
          max-width: 1522px;
            margin:  ${({mb})=> mb? '24px': '70px 120px 0 70px'};

        .listordimte{
          background: #030E05;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          padding: 20px 20px 40px;
          transition: transform .3s ;
          
           &.d1:hover{
            transform: translateY(-40px);
            box-shadow: 1px 0px 30px  #2B33FF;
           }
            &.d2:hover{
            transform: translateY(-40px);
            box-shadow: 1px 0px 30px  #9350FF;
           }
            &.d3:hover{
            transform: translateY(-40px);
            box-shadow: 1px 0px 30px  #FF71FF;
           }
          
          &.d1, .d1{
          border: 1px solid #2B33FF;
          }
            &.d2, .d2{
          border: 1px solid #9350FF
          }
            &.d3, .d3{
          border: 1px solid #FF71FF
          }
        
          
          .ortitle{
                      font-size: 42px;
          font-family: Poppins, Poppins;
          font-weight: bold;
          margin-top: 21px;
          color: #2B33FF;
            &.sized2{
            color: #9350FF;
          }
             &.sized3{
             color:  #FF71FF;;
          }
          }
          .ordsize{
            font-size: 18px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #d1d1d17d;
            margin-top: 21px;
          }
          .btnsss{
            margin-top: 55px;
            display: flex;
            align-items: center;
            gap: 14px;
            font-size: 16px;
            cursor: pointer;
            font-family: PingFang SC, PingFang SC;
            line-height: 50px;
            font-weight: 500;
            color: #FFFFFF;
            width: 212px;
            justify-content: center;
            border: 1px solid #2B33FF;
            &.sized2{
            border: 1px solid #9350FF;
          }
            &.sized3{
            border: 1px solid #FF71FF;;
          }
          }
          .ddcx{
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            margin-top: 24px;
            text-align: center;
          }
        }

      }

      .ddsbix{
        display: flex;
        justify-content: center;
        gap: 40px;
        margin-top: 80px;
        align-items: center;
      
   flex-direction:  ${({mb})=> mb? 'column ': 'inherit'};
      }

      .Documentation{
        background: #030E05;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #0DDB9C;
        width: 540px;
        padding: 24px 47px 24px 24px;
        .times{
          font-size: 32px;
        font-family: Poppins, Poppins;
        font-weight: bold;
        color: #0DDB9C;
        }
        .sized{
          font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #d1d1d17d;
        }
        .bsdcc{
          line-height: 50px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          border: 1px solid #0DDB9C;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          width: 180px;
          text-align: center;
          color: #FFFFFF;
          display: flex;
          margin-top: 50px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          &:hover{
            background-color: #0DDB9C;
           color: #030E05;
          }
          &.ddxx{
            margin-top: 72px;
          }
        }
      }
      .ddxx{

        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 65px;
        .tg{
          width: 150px;
          border-radius: 8px 8px 8px 8px;
        opacity: 1;
        border: 1px solid #717171;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        color: #FFFFFF;
        height: 60px;
        gap: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        }

      }
      .elsds{
        margin-top:40px;
        font-size: 16px;
font-family: PingFang SC, PingFang SC;
font-weight: 500;
color: #FFFFFF;
text-align: center;
padding-bottom: 40px;
      }
`;


const MultiBit  = ()=> {
   const isSmallScreen = useMediaQuery(`(max-width:  920px)`)
  return(
    <MultiBitBox mb={isSmallScreen}>
       <Header/>
      <div className="lds disc">
        <div className="lt">
              <div className="titld">Ordbit Protocol</div>
              <div className="size ">
               Build BRC20 bridges, reduce mint costs, provide lower fees, and enjoy fast and low-consumption mint inscriptions
              </div>
              {/* <div className="contractsize">
                CA: 0x38e382f74dfb84608f3c1f10187f6bef5951de93
              </div> */}
              <div className="swapbtn">
                Trading on uniswap <img src={JT} alt=""   width={14} height={14}/>
              </div>
        </div>
        <div className="rg">
                 <img src={p1} alt="" />

        </div>
    

      </div>
       <div className="lds disc">
     
        <div className="rg">
          <img src={p2} alt="" />


        </div>
           <div className="lt">
              <div className="titld">Ordibit Bridge </div>
              <div className="size ">
               Connects ERC20, BEP20 and other networks with BRC20 to create a broader space for inscriptions, adding staking, earning, and more functions
              </div>
              <div className="swapbtn">
                Enter App <img src={JT2} alt=""   width={14} height={14}/>
              </div>
        </div>
    

      </div>


      {/*  token */}

      {/* <div className="tokenlist"> 
        <div className="token"> <img src={Token1} alt="" /> token1</div>
          <div className="token"> <img src={Token2} alt="" /> token2</div>
            <div className="token"> <img src={Token3} alt="" /> token3</div>
              <div className="token"> <img src={Token4} alt="" /> token4</div>
      </div> */}

      {/* list */}

<div className="tsav"> 

      <div className="list">
         <div className="item">
           <div className="itmetitle">Fast and faster 
            {/* <div className="size2">At MultiBit</div> */}
           </div>
           <div className="itmesize">
           Quick response, no need to wait for a long time, can be quickly minted in ERC20, BEP20 and other networks, connected to BRC20 through a bridge 
           </div>
         </div>
          <div className="item">
           <div className="itmetitle">Protector
            {/* <div className="size2">At MultiBit</div> */}
           </div>
           <div className="itmesize">
          Seek professional audit institutions to protect contracts and cross-chain bridges to ensure the safety of users’ funds.
           </div>
         </div>
           <div className="item">
           <div className="itmetitle">Aggregator
            {/* <div className="size2">At MultiBit</div> */}
           </div>
           <div className="itmesize">
           The BRC20 cross-chain ecological platform will provide users with more services, including staking, earning, farming, etc.
           </div>
         </div>
      </div>
      {/* How it all works */}

      <div className="tiles">
        How it all works
      </div>
      <div className="sizee">
        Ordibit connects BRC20 and the EVM network, and uses the fast block speed of the EVM network to compensate for the congested network of the BTC chain. When the BTC chain is in a state of congestion, people have to pay large and high fees to speed up the completion of transactions. Most of the time, this move will greatly increase the cost of participating in the minting, or even the minting will fail and face huge financial losses. Through bridge connections, we will take advantage of the characteristics of the EVM network to give users the best mint experience at the lowest cost.
      </div>
      <div className="bosd">
        <img src={LTC} alt=""  width={'100%'} height={'100%'}/>
      </div>


       <div className="tiles">
        Ordibit Ecology
      </div>
      <div className="sizee">
        By integrating with BRC20 and numerous projects, we will launch staking, farming, launchpad, DEX aggregator and more Dapps to build Ordibit into a powerful aggregation platform connecting BRC20 and the EVM network.
      </div>

</div>


{/* Ecosystem */}
<Box display={'flex'} justifyContent={'center'}>
  <div className="OrdinalsFarming">
  <div className="listordimte d1">
    <div className="ortitle">AMM</div>
    <div className="ordsize">A leading Automated Market Maker (AMM) at the forefront of decentralized finance (DeFi). Our platform revolutionizes the way users trade and provide liquidity in the crypto space, leveraging cutting-edge smart contracts and advanced algorithms.</div>

    <Box display={'flex'} justifyContent={'center'}>
       <div className="btnsss d1">Launch APP <img src={JT2} alt=""  width={9} height={9}/> </div>

    </Box>

    <div className="ddcx">Coming soon</div>

   
  </div>
   <div className="listordimte d2">
    <div className="ortitle sized2">Farms</div>
    <div className="ordsize">Users need to stake their tokens into the platform’s liquidity pool. This provides the platform with enough liquidity to enable other users to trade on these trading pairs. As a reward for providing liquidity, the platform distributes tokens or other rewards to liquidity providers. These rewards are typically issued in the form of the platform’s native token, and their amount is determined by users’ contributions to the liquidity pool.</div>

    <Box display={'flex'} justifyContent={'center'}>
       <div className="btnsss  sized2">Launch APP <img src={JT2} alt=""  width={9} height={9}/> </div>

    </Box>

    <div className="ddcx">Coming soon</div>

   
  </div>

    <div className="listordimte d3">
    <div className="ortitle sized3">Bridge</div>
    <div className="ordsize">Quickly connect BRC20 and the EVM network, reduce handling fees, reduce the risk of transaction failure, protect users' precious assets, and greatly enhance the trading experience of participating in mint inscriptions</div>

    <Box display={'flex'} justifyContent={'center'}>
       <div className="btnsss  sized3">Launch APP <img src={JT2} alt=""  width={9} height={9}/> </div>

    </Box>

    <div className="ddcx">Coming soon</div>

   
  </div>
</div>

</Box>


<div className="tiles">
       Get Started Now
      </div>
      <div className="sizee">
        Find Resources you need to take advantage of MultiBit
      </div>

{/* Documentation */}
    <Box className="ddsbix" >

        <div className='Documentation'>
              <div className="times">Documentation</div>
              <div className="sized">Read a detailed breakdown of our product offering and smart <br/> contract</div>

              <Box display={'flex'} justifyContent={'flex-end'}>

              <div className="bsdcc">
                Read the docs
                <img src={JT2} alt="" width={9}  height={9}/>
              </div>
              </Box>

         </div>
           <div className='Documentation'>
              <div className="times">Tutorials</div>
              <div className="sized">Watch interactive tutorials to learn how MultiBit works</div>

              <Box display={'flex'} justifyContent={'flex-end'}>

              <Box className="bsdcc ddxx" >
                Read the docs
                <img src={JT2} alt="" width={9}  height={9}/>
              </Box>
              </Box>

         </div>
    </Box>



    <div className="tiles">
      Keep in touch
      </div>

    <div className="ddxx">
        <div className="tg">
          <img src={tg} alt="" onClick={()=> window.open('https://t.me/ordbitprotocol')} />
           Telegram
        </div>
         <div className="tg" onClick={()=> window.open('https://x.com/Ordbitpro')}>
          <img src={tt} alt="" />
           Twitter
        </div>
         <div className="tg">
          <img src={docs} alt="" onClick={()=> window.open('https://ordinarium.co')}  />
           Document
        </div>
    </div>

    <div className="elsds">
      E-mail：xxxxx.com
    </div>



    </MultiBitBox>
  )
}
export default MultiBit;