import { Box, Button, styled, useMediaQuery } from "@mui/material"
import { ConnectButton } from "@rainbow-me/rainbowkit";
import USER from 'img/gr.png'
import TG from  'img/mul/Telegram.png'
import DOC from  'img/mul/docs.png'
import TT from  'img/mul/Twitter.png'
import logo from 'img/mul/ORDBIT.png'
import React, { useState } from "react";
import { handlerError } from "utlis/formatError";

const HeaderBox = styled(Box)<{isSmallScreen?:boolean}>`
    padding: ${({isSmallScreen})=> isSmallScreen? '10px ' : '20px 120px 0 127px '}  ;
    display: flex;
    align-items: center;

.logobox{
  display: flex;
  align-items: center;
 
  .logo{
    width: 40px;
    margin-right: 12px;
  }

  font-size:   ${({isSmallScreen})=> isSmallScreen? '16px ' : '27px '}  ; ;
font-weight: bold;
color: #FFFFFF;
  
}

.nav{
  display: flex;
  .item{
   font-size: 16px;
font-family: PingFang SC, PingFang SC;
font-weight: 500;
color: #9F9F9F;
    cursor: pointer;
    &:hover{
      color:#0DDB9C;
    }
    &.acvtive{
       color: #0DDB9C;

    }
  }
  gap: ${({isSmallScreen})=> isSmallScreen? '25px ' : '50px '}  ; ;
 
  
}
`;

const BtnBox = styled(Box)`
  border-radius: 19px 19px 19px 19px;
    opacity: 1;
    border: 1px solid #0DDB9C;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #0DDB9C;
    padding: 5px 12px;
    cursor: pointer;

`;
const Header = ()=> {


 const isSmallScreen = useMediaQuery(`(max-width:  920px)`)
  const [open, setOpen] = React.useState(false);
  const [opendd, setOpenopendd] = React.useState(false);
 const [modaltext, setmodaltext] =useState<string>('')
   const [txerror, seterror] = useState<any>();

  // const aripd = useAirdrop();

    const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = ()=> {
    setOpen(true);
  }

  const handleClosesss = ()=>{
     if (txerror) {
          setOpenopendd(false)
          seterror(undefined);
      }
  }
  
  // const Faucet =async ()=> {
  //   if (!aripd) return;
  //   setOpenopendd(true);
  // try {
  //     setmodaltext('Waiting for transaction to complete...')
  //  const res =  await aripd.claim();
  //    await res.wait();
  //   setOpenopendd(false);
  // } catch (error) {
  //   console.log('error',error);
  //    seterror(handlerError(error));

  // }

  // }

  return (
    <HeaderBox  isSmallScreen={isSmallScreen}>
      <Box flex={1} display={'flex'} alignItems={'center'}>

         <Box className="logobox" mr={'90px'}>
        <a href="" target="_blank" rel="noopener noreferrer">

         <div className="logo"> <img src={logo} width={'64px'} height={'64px'} alt="" /></div>
         </a>
      </Box>

    <div className="nav">
      <div className="item">home</div>
    </div>

       
      </Box>
     
     
      <Box display={'flex'}  gap={'12px'} alignItems={'center'}> 
        <img src={TT} alt="" onClick={()=> window.open('https://x.com/Ordbitpro')}    width={'20px'} height={'20px'} style={{cursor:'pointer'}}  />
        <img src={TG} alt=""  onClick={()=> window.open('https://t.me/ordbitprotocol')} width={'20px'} height={'20px'}   style={{cursor:'pointer'}}  />
        <img src={DOC} alt="" onClick={()=> window.open('https://ordinarium.co')}  width={'20px'} height={'20px'}   style={{cursor:'pointer'}}  />

        <BtnBox>Launch App</BtnBox>
          
        </Box>

          {/* <EarnizedDialogs open={open} handleClose={handleClose}/> */}


          {/* <CustomizedDialogs open={opendd} handleClose={handleClosesss} isplayGame={false} modaltext={modaltext} txerror={txerror} /> */}
    </HeaderBox>
  )
}
export default Header;