import React from 'react';
import './App.css';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Home from 'pages/MultiBit'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';


function App() {
  return (
    <Routes>
       <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
  );
}

export default App;
