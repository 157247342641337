import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@rainbow-me/rainbowkit/styles.css';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { RainbowKitProvider, getDefaultWallets } from '@rainbow-me/rainbowkit';
import { HashRouter } from 'react-router-dom';
import { goerli } from 'viem/chains';


const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    // mainnet,
   goerli
  ],
  [publicProvider(),alchemyProvider({ apiKey: 'bBUosx2q8Pm3dJKBkoxKxPoW5JwPNFDy' })]
);

const { connectors } = getDefaultWallets({
  appName: 'WDX',
  projectId: 'd9e02d8e4a4bb8fe574b63430fa1cf34',
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>
     <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains} modalSize="compact">
            <App />
      </RainbowKitProvider>
    </WagmiConfig>
    </HashRouter>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
